import { useQuery } from '@apollo/client'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import EventTypeForm from './EventTypeForm'
import RecurrenceInputForm from './RecurrenceInputForm'
import AsyncButton from '../../components/AsyncButton'
import ColorSelector from '../../components/ColorSelector'
import Loading from '../../components/Loading'
import RemindersTable from '../../components/RemindersTable'
import TagsTable from '../../components/TagsTable'
import ColorAvatars from '../../components/colors/ColorAvatars'
import DialogTabs from '../../components/dialogs/DialogTabs'
import EventSaveRecurrenceDialog from '../../components/dialogs/EventSaveRecurrenceDialog'
import FilesTable from '../../components/files/FilesTable'
import ImageMediaField from '../../components/files/ImageMediaField'
import {
  CalendarDataFragment,
  CalendarEventDataFragment,
  EventAccessType,
  EventInput,
  eventsTypesQuery,
  EventTypeDataFragment,
  RecurrenceType,
} from '../../generated/graphql'
import DH from '../../helpers/DH'
import DataHelper from '../../helpers/DataHelper'
import { useCanAccessData } from '../../helpers/GqlHelper'
import { useCalendars } from '../../providers/CalendarContext'
import EventsTypes from '../../schema/EventsTypes'
import TemplateSettingsTab from '../notifications/TemplateSettingsTab'

const useStyles = makeStyles()((theme) => {
  return {
    swatch: {
      width: 432,
      marginTop: 10,
    },
    colorContainer: {
      marginTop: 10,
    },
    radio: {
      padding: 0,
    },
    radioIcon: {
      width: 48,
      height: 48,
    },
    radioIconSelected: {
      width: 48,
      height: 48,
      border: '1px solid white',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    calendarSelectRender: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    calendarSelectRenderIcon: {
      marginRight: theme.spacing(1),
      width: 20,
      height: 20,
    },
    rowIcon: {
      marginRight: theme.spacing(1),
    },
    eventTypeTitle: {
      display: 'flex',
      alignItems: 'center',
    },
    fullHeight: {
      height: '100%',
    },
    errorDialog: {
      color: 'red',
      fontSize: 12,
      textAlign: 'right',
      marginTop: 8,
      marginBottom: 0,
    },
    addSpace: {
      margin: '10px 0px',
    },
  }
})

interface Props {
  open: boolean
  event?: CalendarEventDataFragment
  initData?: Partial<EventInput>

  onClose(): void
  commit(input: EventInput): Promise<void>
}

function getInitialState(
  calendars: CalendarDataFragment[],
  event?: CalendarEventDataFragment,
  initData?: Partial<EventInput>,
  forceReset = false,
): EventInput {
  const baseCalendar: CalendarDataFragment =
    calendars[0] || ({ color: DH.getDefaultColorHex() } as CalendarDataFragment)
  if (event && !forceReset) {
    const {
      accessType,
      allDay,
      attendeeLimit,
      color,
      description,
      end,
      featuredImage,
      files,
      hasAttendeeRegistration,
      isOnline,
      id,
      id_calendar,
      id_type,
      isFinished,
      language,
      location,
      locationName,
      lat,
      lng,
      recurrence,
      start,
      tags,
      title,
      reminders,
    } = event
    return {
      accessType,
      allDay,
      attendeeLimit,
      color,
      description,
      end,
      featuredImageId: featuredImage ? featuredImage.id : undefined,
      files:
        files &&
        files.map((f) => ({
          id: f.id,
          id_media: f.file.id,
          documentTypeId: f.documentType?.id,
        })),
      hasAttendeeRegistration,
      isOnline,
      id,
      id_calendar,
      id_type,
      isFinished,
      language,
      location,
      locationName,
      lat,
      lng,
      organizersIds: event.organizers.map((o) => o.id),
      notificationTemplates: event.notificationTemplates && event.notificationTemplates.map((nt) => nt.notification.id),
      recurrence,
      reminders: (reminders || []).map((r) => DataHelper.removeTypeName(r)),
      start,
      tags: tags && tags.map((t) => ({ key: t.key, value: t.value })),
      title,
    }
  }
  return {
    title: '',
    description: '',
    allDay: false,
    start: dayjs().unix(),
    end: dayjs().add(1, 'hours').unix(),
    id_calendar: baseCalendar.id,
    color: baseCalendar.color,
    id_type: null,
    language: [],
    location: null,
    locationName: null,
    accessType: EventAccessType.Private,
    attendeeLimit: 0,
    hasAttendeeRegistration: false,
    isOnline: false,
    recurrence: {
      type: RecurrenceType.No_Recurrence,
    },
    organizersIds: [],
    ...initData,
  }
}

const EventDialogContent: React.FC<Props> = (props) => {
  const { open, onClose, commit, initData } = props
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { calendars, selectedCalendars } = useCalendars()
  const eventTypesResult = useQuery<eventsTypesQuery>(EventsTypes.GET_EVENTS_TYPES)
  const canAccessTypes = useCanAccessData(eventTypesResult)
  const { error: errorEvTypes, data: dataEvTypes } = useQuery<eventsTypesQuery>(EventsTypes.GET_EVENTS_TYPES)
  const [state, setState] = useState<EventInput | null>(null)
  const [selectedEventType, setSelectedEventType] = useState<EventTypeDataFragment | null>(null)
  const event = props.event
  const [errorEndBeforeStart, setErrorEndBeforeStart] = useState<boolean>(false)
  const [errorStartBeforeToday, setErrorStartBeforeToday] = useState<boolean>(false)
  const [errorMinDate, setErrorMinDate] = useState<boolean>(false)
  const [showSaveRecurrenceDialog, setShowSaveRecurrenceDialog] = useState<boolean>(false)
  const hasRecurrenceUpdates = useMemo(() => {
    return (
      state?.recurrence?.occurrences?.reduce((prev, currentOccurrence) => {
        return prev || !currentOccurrence.id
      }, false) || state?.recurrence?.occurrences?.length !== event?.recurrence?.occurrences?.length
    )
  }, [state, event])

  useEffect(() => {
    setState(getInitialState(selectedCalendars, event, initData))
  }, [open, selectedCalendars, event, initData])

  const eventTypes = useMemo(() => {
    return dataEvTypes?.eventsTypes || []
  }, [dataEvTypes?.eventsTypes])
  useEffect(() => {
    if (eventTypes && eventTypes.length && event && event.id_type) {
      const eventType = eventTypes.find((evType) => evType.id === event.id_type)
      if (eventType) {
        setSelectedEventType(eventType)
      }
    }
  }, [open, eventTypes, event])

  const stateEnd = state && state.end
  const stateStart = state && state.start
  // Checks if end-date is before start-date to set it as invalid.
  useEffect(() => {
    setErrorEndBeforeStart((stateEnd && stateStart && dayjs.unix(stateEnd).isBefore(dayjs.unix(stateStart))) || false)
    setErrorStartBeforeToday((stateStart && dayjs.unix(stateStart).isBefore(dayjs().startOf('day'))) || false)
  }, [stateEnd, stateStart])

  if (!state || errorEvTypes) {
    return <Loading />
  }

  const createEvent = async () => {
    await commit(state)
    setState(getInitialState(selectedCalendars, event, initData, true))
    onClose()
  }
  const handleEventTypeSelection = (e: any) => {
    const value = e.target.value
    const evType: EventTypeDataFragment | undefined = eventTypes.find((et) => et.id === value)
    if (evType && evType !== selectedEventType) {
      // ANY CHANGE HERE MUST BE DONE IN api/scheduling_app_api/src/helpers/EventTypeHelper.ts mergeEventType TOO
      setState({
        ...state,
        id_type: evType.id,
        title: evType.title,
        description: evType.description || state.description,
        end: evType.duration
          ? // if duration exists, add it to time
            dayjs.unix(state.start).add(evType.duration, 'minutes').unix()
          : state.end,
        language: evType.language ? evType.language : state.language,
        location: evType.location || state.location,
        lat: evType.lat || state.lat,
        lng: evType.lng || state.lng,
        locationName: evType.locationName || state.locationName,
        accessType: evType.accessType || state.accessType,
        attendeeLimit: evType.attendeeLimit || state.attendeeLimit,
        tags: evType.tags ? evType.tags.map((tag) => DataHelper.removeTypeName(tag)) : [],
        files: evType.files
          ? evType.files.map((f) => ({ id_media: f.file.id, documentTypeId: f.documentType?.id }))
          : [],
        hasAttendeeRegistration: evType.hasAttendeeRegistration || state.hasAttendeeRegistration,
        isOnline: evType.isOnline || state.isOnline,
        recurrence: evType.recurrence?.type === RecurrenceType.RRule_Recurrence ? evType.recurrence : state.recurrence,
        notificationTemplates: evType.notificationTemplates
          ? evType.notificationTemplates.map((nt) => nt.notification.id)
          : state.notificationTemplates,
        featuredImageId: evType.featuredImage?.id || state.featuredImageId,
        reminders: evType.reminders?.length
          ? evType.reminders?.map((r) => DataHelper.removeTypeName(r))
          : state.reminders,
        organizersIds: evType.organizers?.length ? evType.organizers.map((o) => o.id) : state.organizersIds,
      })
      setSelectedEventType(evType)
    } else if (!evType && selectedEventType) {
      setSelectedEventType(null)
      setState({ ...state, id_type: null })
    }
  }

  const handleEndingTime = (startTime: Dayjs) => {
    if (selectedEventType && selectedEventType.duration) {
      return startTime.add(selectedEventType.duration, 'minutes').unix()
    }
    const currentDuration = dayjs.unix(state.end).diff(dayjs.unix(state.start), 'minutes')
    // It always keeps the current duration of the event in case the user changes its starting time
    if (currentDuration && currentDuration > 0) {
      return startTime.add(currentDuration, 'minutes').unix()
    }
    return startTime.add(1, 'hours').unix()
  }

  const eventTabLabels = [
    t('Details'),
    t('Advanced details'),
    t('Documents'),
    t('Tags'),
    t('Reminders'),
    t('Notification templates'),
  ]

  return (
    <>
      <DialogTitle id="form-dialog-title">{props.event ? t('Edit event') : t('Create event')}</DialogTitle>
      <DialogTabs tabLabels={eventTabLabels}>
        {/* All DialogContent is one tab */}
        <DialogContent className={classes.fullHeight}>
          {!state?.id_calendar && (
            <DialogContentText color="error" textAlign="center" fontWeight="bold">
              {t(
                'Selected Country dose not have a User and Calendar. Please create a user for specific country and log into it to create the calendar!',
              )}
            </DialogContentText>
          )}

          {!props.event && <DialogContentText>{t('Please fill the new event details')}</DialogContentText>}
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <TextField
                required
                className={classes.addSpace}
                variant="standard"
                autoFocus
                label={t('Title')}
                type="text"
                value={state.title}
                onChange={(e) => setState({ ...state, title: e.target.value })}
                fullWidth
              />
              <TextField
                variant="standard"
                className={classes.addSpace}
                label={t('Description')}
                type="text"
                multiline
                value={state.description || ''}
                onChange={(e) => setState({ ...state, description: e.target.value })}
                fullWidth
              />
              <FormControlLabel
                label={t('All-Day')}
                className={classes.addSpace}
                labelPlacement={'end'}
                control={<Checkbox />}
                checked={!!state.allDay}
                onChange={() => setState({ ...state, allDay: !state.allDay })}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} sm>
                  <FormControl required error={errorMinDate}>
                    <DatePicker
                      label={t('Starting Day')}
                      className={classes.addSpace}
                      inputFormat={DH.getDateFormat()}
                      renderInput={(params) => <TextField variant="standard" {...params} error={errorMinDate} />}
                      value={dayjs.unix(state.start).toDate()}
                      disablePast
                      onError={(reason) => {
                        if (reason === 'disablePast') {
                          setErrorMinDate(true)
                        }
                      }}
                      onChange={(date: any) => {
                        setErrorMinDate(false)
                        setState({
                          ...state,
                          start: date.unix(),
                          end: handleEndingTime(date),
                        })
                      }}
                    />
                    {errorMinDate && <FormHelperText>{t('Starting date should not be on the past')}</FormHelperText>}
                  </FormControl>
                </Grid>
                {state.allDay ? null : (
                  <Grid item xs={12} sm={6}>
                    <FormControl required>
                      <TimePicker
                        label={t('Starting Time')}
                        className={classes.addSpace}
                        ampm={DH.isTimeFormat12h()}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                        value={dayjs.unix(state.start).toDate()}
                        onChange={(date: any) => {
                          setState({
                            ...state,
                            start: date.unix(),
                            end: handleEndingTime(date),
                          })
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm>
                  <FormControl required>
                    <DatePicker
                      label={t('Ending Day')}
                      className={classes.addSpace}
                      inputFormat={DH.getDateFormat()}
                      renderInput={(params) => <TextField variant="standard" {...params} />}
                      value={dayjs.unix(state.end).toDate()}
                      minDate={dayjs.unix(state.start)}
                      onChange={(date: Dayjs) => setState({ ...state, end: date.unix() })}
                    />
                  </FormControl>
                </Grid>
                {state.allDay ? null : (
                  <Grid item xs={12} sm={6}>
                    <FormControl required error={errorEndBeforeStart}>
                      <TimePicker
                        className={classes.addSpace}
                        label={t('Ending Time')}
                        ampm={DH.isTimeFormat12h()}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} error={errorEndBeforeStart} />
                        )}
                        value={dayjs.unix(state.end).toDate()}
                        onChange={(date: any) => setState({ ...state, end: date.unix() })}
                      />
                      {errorEndBeforeStart && (
                        <FormHelperText>{t('Ending time should happen after starting time!')}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <FormControl className={classes.addSpace}>
                <FormLabel>{t('Color')}</FormLabel>
                <ColorSelector
                  colorIndex={400}
                  color={state.color}
                  onChange={(newColor) => setState({ ...state, color: newColor })}
                />
              </FormControl>
              <TextField
                select
                variant="standard"
                className={classes.addSpace}
                label={t('Calendar')}
                fullWidth
                value={state.id_calendar}
                onChange={(e) => setState({ ...state, id_calendar: e.target.value as string })}
                inputProps={{
                  name: 'calendar-id',
                  id: 'calendar-id',
                }}
                SelectProps={{
                  renderValue: (value) => {
                    const cal: any = calendars.find((c) => c.id === value)
                    const CalIcon = ColorAvatars.getColorAvatar(cal.color)
                    return (
                      <div className={classes.calendarSelectRender}>
                        <CalIcon className={classes.calendarSelectRenderIcon} />
                        {cal.name}
                      </div>
                    )
                  },
                }}
              >
                {calendars.map((cal) => {
                  const CalIcon = ColorAvatars.getColorAvatar(cal.color)
                  return (
                    <MenuItem key={cal.id} value={cal.id}>
                      <ListItemAvatar>
                        <CalIcon />
                      </ListItemAvatar>
                      <ListItemText primary={cal.name} />
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ImageMediaField
                fullWidth
                id="featured-image"
                idMedia={state.featuredImageId ?? undefined}
                onChange={(id) => setState({ ...state, featuredImageId: id })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        {/* Another tab (Advanced details) */}
        <DialogContent className={classes.fullHeight}>
          <DialogContentText>{t('Select an event type')}</DialogContentText>
          {canAccessTypes && (
            <TextField
              variant="standard"
              className={classes.addSpace}
              label={t('Event Type')}
              select
              fullWidth
              value={(selectedEventType && selectedEventType.id) || 'no-type'}
              onChange={handleEventTypeSelection}
              inputProps={{
                name: 'event-type-id',
                id: 'event-type-id',
              }}
              SelectProps={{
                renderValue: () => {
                  if (selectedEventType) {
                    const EvTypeIcon = ColorAvatars.getColorAvatar(selectedEventType.color)
                    return (
                      <div className={classes.calendarSelectRender}>
                        <EvTypeIcon className={classes.calendarSelectRenderIcon} />
                        {selectedEventType.name}
                      </div>
                    )
                  } else {
                    return <div className={classes.calendarSelectRender}>{t('No event type')}</div>
                  }
                },
              }}
            >
              <MenuItem key={'no-type'} value={'no-type'} className={classes.addSpace}>
                <ListItemText primary={t('No event type')} />
              </MenuItem>
              {eventTypes.map((eventType) => {
                const EventTypeIcon = ColorAvatars.getColorAvatar(eventType.color)
                return (
                  <MenuItem key={eventType.id} value={eventType.id}>
                    <ListItemAvatar>
                      <EventTypeIcon />
                    </ListItemAvatar>
                    <ListItemText primary={eventType.name} />
                  </MenuItem>
                )
              })}
            </TextField>
          )}
          {!canAccessTypes && (
            <div>
              <Loading variant="inline" />
              {t('Loading event types')}
            </div>
          )}

          <EventTypeForm state={state} setState={setState} shortVersion />
          <RecurrenceInputForm
            allDay={state.allDay}
            date={state.start}
            onChange={(recurrence) => {
              setState({
                ...state,
                recurrence,
              })
            }}
            originalEvent={event}
            value={state.recurrence}
          />
        </DialogContent>
        <DialogContent className={classes.fullHeight}>
          <FilesTable ownerNode={event} files={state.files || []} setFiles={(files) => setState({ ...state, files })} />
        </DialogContent>
        <DialogContent className={classes.fullHeight}>
          <TagsTable tags={state.tags || []} setTags={(tags) => setState({ ...state, tags })} />
        </DialogContent>
        <DialogContent className={classes.fullHeight}>
          <RemindersTable
            reminders={state.reminders || []}
            setReminders={(reminders) => setState({ ...state, reminders })}
          />
        </DialogContent>
        <DialogContent className={classes.fullHeight}>
          <TemplateSettingsTab
            defaultTemplates={state.notificationTemplates || []}
            setDefaultTemplates={(notificationTemplates) => setState({ ...state, notificationTemplates })}
          />
        </DialogContent>
      </DialogTabs>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="text">
          {t('Cancel')}
        </Button>
        <Tooltip title={(state.isFinished as boolean) ? t('Finished events cannot be edited') : ''}>
          <span>
            <AsyncButton
              onClick={() => {
                if (state.recurrence?.type && state.recurrence?.type !== RecurrenceType.No_Recurrence) {
                  setShowSaveRecurrenceDialog(true)
                } else {
                  createEvent()
                }
              }}
              disabled={
                !state?.title ||
                !state.id_calendar ||
                !state.start ||
                !state.end ||
                !state.color ||
                errorEndBeforeStart ||
                errorStartBeforeToday ||
                (state.isFinished as boolean)
              }
              variant="text"
            />
          </span>
        </Tooltip>
      </DialogActions>
      <EventSaveRecurrenceDialog
        saveOption={state.recurrenceUpdateMode}
        setSaveOption={(recurrenceUpdateOption) => {
          setState({ ...state, recurrenceUpdateMode: recurrenceUpdateOption })
        }}
        disableCustomSave={hasRecurrenceUpdates}
        open={showSaveRecurrenceDialog}
        onClose={() => setShowSaveRecurrenceDialog(false)}
        onSave={createEvent}
      />
    </>
  )
}

export default EventDialogContent
