import { useQuery } from '@apollo/client'
import CalendarIcon from '@mui/icons-material/CalendarToday'
import CloudDoneIcon from '@mui/icons-material/CloudUpload'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NotesIcon from '@mui/icons-material/EventNote'
import AttendeesIcon from '@mui/icons-material/People'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder'
import { Tooltip } from '@mui/material'
import Badge from '@mui/material/Badge/Badge'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import dayjs from 'dayjs'
import { useRouter } from 'found'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import ActionDialogTitle from './ActionDialogTitle'
import GraphqlError from '../../components/GraphqlError'
import Loading from '../../components/Loading'
import { CanBeDeleted, CanBeUpdated } from '../../components/acl/CanBe'
import { UserCanRead } from '../../components/acl/UserCanDo'
import ColorAvatars from '../../components/colors/ColorAvatars'
import DateRange from '../../components/dates/DateRange'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import ApiNodeInfo from '../../components/owned-api-node/ApiNodeInfo'
import { CalendarEventBasicDataFragment, eventQuery, eventQueryVariables } from '../../generated/graphql'
import { EntityNames } from '../../helpers/DataHelper'
import routes from '../../helpers/routes'
import CalendarEvents from '../../schema/CalendarEvents'

const useStyles = makeStyles()((theme) => {
  return {
    dialog: {
      minWidth: 400,
    },
    color: {
      width: 20,
      height: 20,
      marginRight: theme.spacing(1),
    },
    rowIcon: {
      marginRight: theme.spacing(1),
    },
    eventTitle: {
      display: 'flex',
      alignItems: 'center',
      margin: '7px',
    },
    paddedContainer: {
      marginLeft: 20 + theme.spacing(1),
    },
    actionButton: {
      color: theme.palette.grey[500],
    },
  }
})

interface Props {
  open: boolean
  event: CalendarEventBasicDataFragment

  onClose(): void
  editEvent(event: CalendarEventBasicDataFragment): void
  deleteEvent(id: string): void
  manageAttendance(): void
}

const ViewEventDialog: React.FC<Props> = (props) => {
  const { open, onClose, editEvent, event, deleteEvent, manageAttendance } = props

  const { router } = useRouter()
  const { classes } = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const deleteAction = () => {
    if (event.id) {
      deleteEvent(event.id)
    }
    onClose()
  }
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const ColorAvatar = ColorAvatars.getColorAvatar(event.color)
  const { loading, error, data } = useQuery<eventQuery, eventQueryVariables>(CalendarEvents.GET_EVENT, {
    variables: {
      id: event.id,
    },
  })
  if (error) {
    return <GraphqlError error={error} />
  }
  if (loading || !data) {
    return <Loading />
  }
  const fullEvent = data.event
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
        className={classes.dialog}
      >
        <ActionDialogTitle id="form-dialog-title" onClose={onClose}>
          <CanBeUpdated entity={event}>
            <Tooltip title={event.isFinished ? t('Finished events cannot be edited') : t('Edit event')}>
              <span>
                <IconButton
                  aria-label="edit"
                  className={classes.actionButton}
                  onClick={() => editEvent(event)}
                  disabled={event.isFinished as boolean}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          </CanBeUpdated>
          <IconButton
            aria-label="manage attendance"
            className={classes.actionButton}
            onClick={manageAttendance}
            size="large"
          >
            <Badge badgeContent={event.attendees.length} invisible={!event.attendees.length} color="primary">
              <AttendeesIcon />
            </Badge>
          </IconButton>
          <CanBeDeleted entity={event}>
            <IconButton
              aria-label="delete"
              className={classes.actionButton}
              onClick={() => setShowDeleteConfirmDialog(true)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </CanBeDeleted>
          <ApiNodeInfo node={event} />
          <UserCanRead entityName={EntityNames.EventImport}>
            {!!fullEvent.eventImport && (
              <IconButton
                title={t(`This event has been imported in '${fullEvent.eventImport.name}' import`)}
                aria-label="go to import"
                className={classes.actionButton}
                onClick={() => {
                  router.push(routes.app_settings_import_edit + '/' + fullEvent.eventImport.id)
                }}
                size="large"
              >
                <CloudDoneIcon />
              </IconButton>
            )}
          </UserCanRead>
        </ActionDialogTitle>
        <DialogContent>
          <div className={classes.eventTitle}>
            <ColorAvatar className={classes.color} />
            <Typography variant="h6">{event.title}</Typography>
          </div>
          <DialogContentText className={classes.paddedContainer}>
            <DateRange start={event.start} end={event.end} />
          </DialogContentText>
          <DialogContentText className={classes.eventTitle}>
            <QueryBuilderIcon className={classes.rowIcon} />
            {dayjs.unix(Number(event.start)).format('HH:mm')}
          </DialogContentText>
          {event?.organizers.length > 0 && (
            <DialogContentText className={classes.eventTitle}>
              <PeopleOutlineIcon className={classes.rowIcon} />
              {event?.organizers.map((organizer) => organizer.email).join(', ')}
            </DialogContentText>
          )}

          {event.description && (
            <DialogContentText className={classes.eventTitle}>
              <NotesIcon className={classes.rowIcon} /> {event.description}
            </DialogContentText>
          )}
          <DialogContentText className={classes.eventTitle}>
            <CalendarIcon className={classes.rowIcon} />
            {event.calendar.name}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        open={showDeleteConfirmDialog}
        onClose={() => setShowDeleteConfirmDialog(false)}
        onConfirm={deleteAction}
        title={t('Delete event')}
        text={t('Are you sure you want to delete this event?')}
      />
    </>
  )
}

export default ViewEventDialog
